import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { layer } from 'src/app/shared/models/layer.model';
import { LayerItemFlatNode } from 'src/app/shared/models/tree/LayerItemFlatNode';
import { LayersService } from '../../services/layers.service';
import { MapService } from '../../services/map.service';
import { NavService } from '../../services/nav.service';
import { RemoveLayerObjectComponent } from '../remove-layer-object/remove-layer-object.component';
import { ApiService } from 'src/app/services/api';


@Component({
  selector: 'app-attr-table',
  templateUrl: './attr-table.component.html',
  styleUrls: ['./attr-table.component.css']
})
export class AttrTableComponent implements OnInit, OnDestroy {
  isVisible = false;
  display = false;
  layers = [];
  cols = [];
  filterParams = {};
  subTable = [];
  totalRecords: number;
  layerNameRu: string;
  loading: boolean;

  subTableData = `[{"owner_id":281,"owner":"ТОО \\"КОЖД\\"","max_cdate":"2018-06-21 19:39:22.354778"},
  {"owner_id":280,"owner":"АО \\"Павлодар тепловые сет","max_cdate":"2018-06-21 19:39:22.354778"},
  {"owner_id":284,"owner":"АО \\"Павлодар тепловые сСе","max_cdate":"2018-06-21 19:39:22.354778"}]`;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private navSvc: NavService,
    private lyrSvc: LayersService,
    private mapSvc: MapService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.initToggleAttrTablePanel();
    this.subTable = JSON.parse(this.subTableData);
    this.subTable.forEach((d) => {
      d.max_cdate = moment(Date.parse(d.max_cdate)).format('YYYY-MM-DD HH:m:s');
    });
  }

  ngOnDestroy() {
    this.navSvc.toggleAttrTablePanel.unsubscribe();
  }

  initToggleAttrTablePanel() {
    this.navSvc.toggleAttrTablePanel.subscribe((attrTableModel: LayerItemFlatNode) => {
      if (attrTableModel) {
        this.isVisible = true;
        // this.fetchFeatureData(attrTableModel.lyrname, attrTableModel.featureCount);
        this.layerNameRu = attrTableModel.layerNameRu;
        this.getAttributes(attrTableModel);
      }
    });
  }

  getAttributes(layer_: LayerItemFlatNode) {
    this.lyrSvc.getLayerAttributes(layer_).subscribe((data: layer.ObjectAttributes[]) => {
      this.fetchFeatureData(layer_, data);
    }, error => {
      console.log(error);
      this.fetchFeatureData(layer_, null);
    });
  }

  fetchFeatureData(layerData: LayerItemFlatNode, objectAttributes: layer.ObjectAttributes[]) {
    this.filterParams = {};
    this.http.post(`/eqyzmet/api/layers/${layerData.id}/objects/filter?page=${0}` +
      ((layerData && layerData.isEditableTable) ? '&local=true' : ''), this.filterParams).subscribe(data => {
        if (data) {
          this.layers = [];
          this.cols = [];
          this.fillAttrs(data, objectAttributes);
        }
      }, error => {
        this.layers = [];
        this.cols = [];
        this.api.showErrorMessage();
        console.log(error);
      });
  }

  fillAttrs(data, objectAttributes: layer.ObjectAttributes[]) {
    const props = data.layers[0]; // .features[0].properties;

    for (const prop in props) {
      if (prop.indexOf('shape_') < 0 && prop.indexOf('uid') < 0 && prop.indexOf('geom')) {
        // if (attributes != null && attributes.length > 0) {
        //   let attr = attributes.filter(function (attribute) {
        //     if (prop === attribute.nameEn) {
        //       return attribute;
        //     }
        //   })
        //   attr = attributes == null ? prop : attr[0].nameRu;
        // } else {
        //   attr = prop;
        // }
        let attr;
        if (objectAttributes && objectAttributes.length > 0) {
          attr = objectAttributes.filter(a => a.nameEn === prop);
        }
        if (attr.length > 0) {
          attr = objectAttributes ? attr[0].nameRu : prop;
        } else {
          attr = prop;
        }

        this.cols.push({
          field: prop,
          header: attr,
          fieldType: typeof props[prop]
        });
      }
    }

    this.fillData(data);
  }

  fillData(data) {
    this.totalRecords = data.count;
    for (let i = 0; data.layers.length > i; i++) {
      const feat = data.layers[i];
      const featData = {};
      if (feat) {
        const props = feat;
        for (const prop in props) {
          if (prop !== 'shape_leng' && prop !== 'shape_area'
            //  && prop !== 'geom'
          ) {
            featData[prop] = prop === 'kp_cdate' ? moment(Date.parse(props[prop])).format('YYYY-MM-DD HH:m:s') : props[prop];
          }
        }
      }
      this.layers.push(featData);
    }
  }

  showDialog() {
    this.display = true;
  }

  shonOnMap(info) {
    // const resp = this.lyrSvc.getFeatureData(this.layerName, info.kp_uuid);
    // resp.subscribe(
    //   (data: any) => {
    //     if (data) {
    //       this.mapSvc.showPolygon(data);
    //     }
    //   },
    //   error => {
    //     console.log('error: ', error);
    //   }
    // );
  }

  editSelectedLayerObject(layerObject) {
    this.lyrSvc.isEditable = true;
    this.lyrSvc.editedLayerObject.next(layerObject);

    const geom = JSON.parse(layerObject.geom);
    const geoJsonData: any = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {},
        geometry: geom
      }]
    };
    if (geoJsonData.features[0].geometry) {
      geoJsonData.features[0].geometry.coordinates = geoJsonData.features[0].geometry.coordinates[0];
      this.mapSvc.showFeatureGeom(geoJsonData);
    } else {
      this.snackBar.open('Объект не найден на карте!', '', { duration: 3000 } );
    }
  }

  deleteSelectedLayerObject(layerObject, event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(RemoveLayerObjectComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeLayerObject(layerObject, event);
        this.lyrSvc.isEditable = false;
        this.lyrSvc.editedLayerObject.next(null);
      }
    });
  }

  removeLayerObject(layerObject, event) {
    let layerObjectId = null;
    if (layerObject.id) {
      layerObjectId = layerObject.id;
    } else if (layerObject.gid) {
      layerObjectId = layerObject.gid;
    }
    this.lyrSvc.removeLayerObject(this.lyrSvc.selectedLayer.id, layerObjectId)
      .pipe().subscribe((data: any) => {
        this.lyrSvc.toggleLayerByName(this.lyrSvc.selectedLayer.layerName);
        this.lyrSvc.toggleLayerByName(this.lyrSvc.selectedLayer.layerName);
      }, error => {
        this.api.showErrorMessage();
        console.log(error);
      });
    this.mapSvc.clearEditableLayers();
    this.initToggleAttrTablePanel();
    this.subTable = JSON.parse(this.subTableData);
    this.subTable.forEach((d) => {
      d.max_cdate = moment(Date.parse(d.max_cdate)).format('YYYY-MM-DD HH:m:s');
    });
    const index = this.layers.indexOf(layerObject);
    if (index > -1) {
      this.layers.splice(index, 1);
    }
  }

  loadLayersLazy(event) {
    if (this.lyrSvc.selectedLayer && this.lyrSvc.selectedLayer.layerName
      && Object.keys(event.filters).length === 0) {
        this.loading = true;
        const page = event.first / 100;
        this.http.post(`/eqyzmet/api/layers/${this.lyrSvc.selectedLayer.id}/objects/filter?page=${page}` +
          ((this.lyrSvc.selectedLayer && this.lyrSvc.selectedLayer.isEditableTable) ? '&local=true' : ''),
          this.filterParams).subscribe(data => {
            this.layers = [];
            this.fillData(data);
            this.loading = false;
          }, error => {
            this.api.showErrorMessage();
            console.log(error);
          });
    }
  }

  filterTable(col, value, matchMode) {
    this.filterParams[`${value}`] = col;
    if (value === 'gid') {
      this.filterParams[`${value}`] = Number(col);
    }
    if (value === 'owner_id') {
      this.filterParams[`${value}`] = Number(col);
    }
    this.loading = true;
    this.http.post(`/eqyzmet/api/app/filter/layer/${0}?layerName=${this.lyrSvc.selectedLayer.layerName}`, this.filterParams
    ).subscribe(res => {
      if (res) {
        this.loading = false;
        this.layers = [];
        this.fillData(res);
      }
    }, error => {
      this.api.showErrorMessage();
      console.log(error);
    });
  }

  closeTable() {
    this.isVisible = false;
    this.mapSvc.clearEditableLayers();
  }

}
