import { LayerBase } from '../models/layer-base';

export class LayerNumberBox extends LayerBase<number> {
    controlType = 'textbox';
    type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || '';
    }
}
