export class LayerItemNode {
  children: LayerItemNode[];
  item: string;

  id: number;
  communalId: number;

  layerName: string;
  layerNameRu: string;
  layerGeom: string;
  layerType: string;
}
