import { Component, OnInit } from '@angular/core';

import {ApiService} from '../../../services/api';

@Component({
  selector: 'app-backup-panel',
  templateUrl: './backup-panel.component.html',
  styleUrls: ['./backup-panel.component.css']
})
export class BackupPanelComponent implements OnInit {
  backups: any[] = [];
  loading = false;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    this.api.get('layers/backup/list').subscribe((data: any) => {
      console.log(data);
      data.sort((a, b) => (a.fileName < b.fileName) ? 1 : -1);
      this.backups = data;
    });
  }

  downloadFile(fileName: string) {
    location.href = `/eqyzmet/api/layers/backup/download/${fileName}`;
  }

  createBackup() {
    this.loading = true;
    this.api.post('layers/backup').subscribe((data: any) => {
      this.getData();
      this.loading = false;
      if (data.status !== 200) {
        alert('Ошибка. Обратитесь к администратору');
      }
    }, (error: any) => {
      alert('error');
    });
  }

  restore(fileName) {
    if (this.loading) {
      alert('Дождитесь восстановления резервного копирования');
    }
    const confirmResult = confirm('Вы уверены, что хотите восстановить? Все данные измененные после резервного копирования будут удалены');
    if (confirmResult === true) {
      this.loading = true;
      this.api.post(`layers/restore/${fileName}`).subscribe((data: any) => {
        this.loading = false;
        console.log('data', data);
      }, (data: any) => {
        this.loading = false;
        alert('Произошла ошибка, обратитесь к администратору');
      });
    }
  }

}
