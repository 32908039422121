import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { auth } from '../shared/models/auth.model';
import { Subject } from 'rxjs';
import { ApiService } from './api';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
    jwtHelper: JwtHelperService;
    currentUser: auth.User = null;

    userInfo$ = new Subject();

    constructor(private api: ApiService,
                private http: HttpClient) {
        this.init();
    }

    init() {
        this.jwtHelper = new JwtHelperService();
        const userSubscription = this.getCurrentUser();
        if (userSubscription) {
            userSubscription.subscribe((data: any) => {
                if (data) {
                    this.currentUser = data;
                    this.userInfo$.next(this.currentUser);
                }
            });
        } else {
            this.userInfo$.next(this.currentUser);
        }
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('access_token');
        return !this.jwtHelper.isTokenExpired(token);
    }

    public getTokenData(): any {
        const token = localStorage.getItem('access_token');
        var decodedToken = this.jwtHelper.decodeToken(token);
        if(decodedToken){
            if(!decodedToken.authorities){
                decodedToken.authorities = ['ROLE_APP'];
            }
        }
        return decodedToken;
    }

    public getCurrentUser(): any {
        const tokenData = this.getTokenData();
        if (tokenData && tokenData.userId) {
            return this.api.get('users/' + tokenData.userId);
        }
        return null;
    }

    public getCurrentDetailFromGeoportal(): any {
        return this.api.getFromGeoportal('rest/users/detail');
    }


    public logIn(username: string, password: string) {
        return this.http.post('/auth/token', { username: username, password: password });
    }

    public logOut(): void {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        this.userInfo$.next(null);
        // this.currentUser = null;
    }
}
