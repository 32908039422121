import {Component, OnDestroy, OnInit} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { of as ofObservable, Observable } from 'rxjs';
import { MapService } from '../../services/map.service';
import { LayersService} from '../../services/layers.service';
import { NavService } from '../../services/nav.service';
import {MatDialog, MatDialogConfig, MatSnackBar} from '@angular/material';
import { LayerAttrFormWindowComponent } from '../layer-attr-form-window/layer-attr-form-window.component';
import {AuthService} from '../../services/auth.service';

import {LayerItemFlatNode} from '../../shared/models/tree/LayerItemFlatNode';
import {LayerItemNode} from '../../shared/models/tree/LayerItemNode';
import {LayersDatabaseService} from '../../services/layers.database.service';
import {auth} from '../../shared/models/auth.model';
import {BackupPanelComponent} from './backup-panel/backup-panel.component';

@Component({
  selector: 'app-layers-panel',
  templateUrl: './layers-panel.component.html',
  styleUrls: ['./layers-panel.component.css'],
  providers: [LayersDatabaseService]
})
export class LayersPanelComponent implements OnInit, OnDestroy {
  showAttrByLayerId = null;
  layerAttributesModel: LayerItemFlatNode = null;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<LayerItemFlatNode, LayerItemNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<LayerItemNode, LayerItemFlatNode>();
  /** A selected parent node to be inserted */
  selectedParent: LayerItemFlatNode | null = null;
  /** The new item's name */
  communalTitle = 'По категориям';
  treeControl: FlatTreeControl<LayerItemFlatNode>;
  treeFlattener: MatTreeFlattener<LayerItemNode, LayerItemFlatNode>;
  dataSource: MatTreeFlatDataSource<LayerItemNode, LayerItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<LayerItemFlatNode>(true /* multiple */);
  currentUser: auth.User = null;
  curCommunalLyrs = null;

  constructor(
    private mapSvc: MapService,
    private lyrSvc: LayersService,
    private _database: LayersDatabaseService,
    private navSvc: NavService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<LayerItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });

    // this.lyrSvc.layerAttributesModel.subscribe(data => {
    //   this.layerAttributesModel = data;
    //   this.layers = [];
    //   this.layers = this.getLayerAttributes();
    //   if (this.layerDynamicForm) {
    //     this.layerDynamicForm.layers = this.layers;
    //   }
    // });
  }
  ngOnInit() {
    this._database.initialize();
    this.initCurrenUserData();
  }

  ngOnDestroy(): void {
    this.authService.userInfo$.unsubscribe();
  }

  initCurrenUserData() {
    this.authService.userInfo$.subscribe((data: auth.User) => {
      if (data) {
        this.currentUser = data;
        const tokenData = this.authService.getTokenData();
        if (tokenData) {
          this.curCommunalLyrs = this._database.getCommunalLayers(this.currentUser.organization.communal.id);
          this.communalTitle = ( this.curCommunalLyrs && this.curCommunalLyrs.length > 0 ) ?
            this.curCommunalLyrs[0].communal.nameRu : 'По категориям';
          this.curCommunalLyrs.forEach(function(element) { element.isLayerShown = false; });
        }
      } else {
        this.currentUser = null;
      }
    });
  }

  showAttrTable(layer: any) {
    this.dialog.closeAll();
    if (layer.isLayerShown) {
      const attrTableModel: LayerItemFlatNode = {
        id: layer.id,
        item: layer.item,
        layerName: 'local_' + layer.layerName,
        layerNameRu: layer.nameRu,
        layerGeom: layer.geomType,
        layerType: layer.layerType,
        communalId: layer.communalId,
        isEditableTable: false,
        isOriginShown: layer.isLayerShown,
        expandable: false,
        level: null
      };
      this.showAttrByLayerId = layer.id;
      this.lyrSvc.selectedLayer = attrTableModel;
      this.layerAttributesModel = attrTableModel;
      this.navSvc.toggleAttrTablePanel.next(attrTableModel);
    }
  }

  getLevel = (node: LayerItemFlatNode) => node.level;
  isExpandable = (node: LayerItemFlatNode) => node.expandable;
  getChildren = (node: LayerItemNode): Observable<LayerItemNode[]> => ofObservable(node.children);
  // getChildren = (node: LayerItemNode): LayerItemNode[] => node.children;
  hasChild = (_: number, _nodeData: LayerItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: LayerItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: LayerItemNode, level: number) => {
    // console.log('transformer node: ', node);
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new LayerItemFlatNode();
    // console.log('transformer flatNode: ', flatNode);
    flatNode.item = node.item;
    flatNode.layerName = node.layerName;
    flatNode.layerNameRu = node.layerNameRu;
    // flatNode.layerGeom = node.layerGeom;
    // flatNode.layerType = node.layerType;
    // flatNode.communalId = node.communalId;
    // flatNode.isEditableTable = false;
    // flatNode.isOriginShown = false;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: LayerItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: LayerItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: LayerItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: LayerItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: LayerItemFlatNode): void {
    let parent: LayerItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: LayerItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: LayerItemFlatNode): LayerItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  nodeSelected(node: any, chbxNode?) {
    // node.isEditableTable = this.checkUserCommunal(node.communalId);
    if (node.layerName) {
      // console.log('nodeSelected node: ', node);
      this.checklistSelection.toggle(node);
      this.lyrSvc.toggleLayerByName(node.layerName);
    }
  }

  checkUserCommunal(communalId: number) {
    const user = this.authService.currentUser;
    // console.log('user: ', user);
    // console.log('input communalId: ', communalId);
    // console.log('userrrr communal id: ', user.organization.communal.id);
    if (user.organization.communal.id === Number(communalId)) {
      return true;
    } else {
      return false;
    }
  }

  toggleCurCommunalLayer(node) {
    // console.log('node: ', node);
    node.isLayerShown = !node.isLayerShown;
    this.showCurCommunalLayer(node, true);
  }

  showCurCommunalLayer(node: any, isWork: boolean) {
    if (isWork) {
      this.lyrSvc.toggleLayerByName('local_' + node.layerName);
    } else {
      this.lyrSvc.toggleLayerByName('history_' + node.layerName);
    }
    // console.log('node layerName: ', node.layerName);
    // console.log('node zxc: ', localCommunalLyrs.selected);
  }

  exportCurrentLayer(node: any) {
    // button.style.display = 'none';
    const syncReq = this.lyrSvc.layerSync(Number(node.id));
    syncReq.subscribe(
      (data) => {
        // button.style.display = 'block';
        this.snackBar.open('Экспорт успешно выполнен!', '', {
          duration: 5000,
          verticalPosition: 'top'
        });
      },
      (error) => {
        this.snackBar.open(error, '', {
          duration: 5000,
          verticalPosition: 'top'
        });
      }
    );
  }

  toggleLayerToolsVisibility(control: any, show: boolean, chbxNode: any, node: any) {
    // console.log('toggleLayerToolsVisibility');
    // console.log('chbxNode:', chbxNode);
    // console.log('node:', node);
    if (show) {
      control.style.display = ( chbxNode.checked && node.isEditableTable && node.layerType === 'LOC') ? 'block' : 'none';
    } else {
      control.style.display = 'none';
    }
  }

  openBackupPanel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    // dialogConfig.data = souvenir;

    const dialogRef = this.dialog.open(BackupPanelComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
