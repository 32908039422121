import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LayerBase } from 'src/app/shared/models/layer-base';

@Component({
  selector: 'app-dynamic-layer-form',
  templateUrl: './dynamic-layer-form.component.html',
  styleUrls: ['./dynamic-layer-form.component.css']
})
export class DynamicLayerFormComponent {

  @Input() layer: LayerBase<any>;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.layer.key].valid; }

}
