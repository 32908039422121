import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {
  ButtonModule,
  CardModule,
  CodeHighlighterModule,
  DataTableModule,
  DialogModule,
  GrowlModule,
  ListboxModule,
  OverlayPanelModule,
  PaginatorModule,
  ScrollPanelModule,
  SidebarModule,
  TabViewModule
} from 'primeng/primeng';

import {TableModule} from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SidebarModule,
    ButtonModule,
    TabViewModule,
    CodeHighlighterModule,
    ListboxModule,
    ScrollPanelModule,
    OverlayPanelModule,
    DataTableModule,
    TableModule,
    PaginatorModule,
    DialogModule,
    GrowlModule,
    CardModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    SidebarModule,
    ButtonModule,
    TabViewModule,
    CodeHighlighterModule,
    ListboxModule,
    ScrollPanelModule,
    OverlayPanelModule,
    DataTableModule,
    TableModule,
    PaginatorModule,
    DialogModule,
    GrowlModule,
    CardModule
  ]
})
export class PrimeModule {
}
