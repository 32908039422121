
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';


import { CONFIG } from '../config';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ApiService {
    constructor(private http: HttpClient,
                private snackBar: MatSnackBar) { }

    public get(restPath: string, parms?, auth = true): Observable<any> {
        const url: string = CONFIG.SERVICES_URL + restPath;
        const token = localStorage.getItem('access_token');
        const headers = auth ? {
            'Authorization': 'Bearer ' + token
        } : {};
        return this.http.get(url, {
            headers: new HttpHeaders(headers),
            params: parms,
        });
    }

    public getFromGeoportal(restPath: string, parms?, auth = true): Observable<any> {
        const token = localStorage.getItem('token');
        const url: string = CONFIG.GEOPORTAL_SERVICES_URL + restPath+"?token="+token;
        return this.http.get(url);
    }

    public getArrayBuffer(url_path: string, params: any = null): Observable<HttpResponse<any>> {
        const url: string = CONFIG.SERVICES_URL + url_path;
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.get<any>(url, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    public post(url_path: string, body: any = null, parms?, auth = true): Observable<HttpResponse<any>> {
        const url: string = CONFIG.SERVICES_URL + url_path;
        const token = localStorage.getItem('access_token');
        const headers = auth ? {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        } : {};
        return this.http.post<any>(url, body, {
            headers: new HttpHeaders(headers),
            observe: 'response',
            params: parms
        });
    }

    public postArrayBuffer(url_path: string, body: any = null, params: any = null): Observable<HttpResponse<any>> {
        const url: string = CONFIG.SERVICES_URL + url_path;
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post<any>(url, body, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    public put(url_path: string, body: any, params: any = null): Observable<HttpResponse<any>> {
        const url: string = CONFIG.SERVICES_URL + url_path;
        const token = localStorage.getItem('access_token');
        return this.http.put<any>(url, body, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }),
            observe: 'response'
        });
    }

    public delete(restPath: string, parms?): Observable<any> {
        const url: string = CONFIG.SERVICES_URL + restPath;
        const token = localStorage.getItem('access_token');
        return this.http.delete(url, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          }),
          params: parms,
          observe: 'response'
        });
    }

    public showErrorMessage() {
        this.snackBar.open('Произошка ошибка. Попробуйте позднее!', '', {
            duration: 7000,
            verticalPosition: 'top'
        });
    }
}
