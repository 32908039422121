import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LayerItemNode} from '../shared/models/tree/LayerItemNode';

@Injectable()
export class LayersDatabaseService {
  dataChange = new BehaviorSubject<LayerItemNode[]>([]);

  get data(): LayerItemNode[] { return this.dataChange.value; }

  TREE_DATA = [{
    'id': 5,
    'layerName': null,
    'nameKz': 'Теплоснабжение и теплоотведение',
    'nameRu': 'Теплоснабжение и теплоотведение',
    'geomType': null,
    'parentId': 0,
    'communal': {
      'id': 4,
      'nameRu': 'Теплоснабжение',
      'nameKk': null,
      'nameEn': null
    }
  }, {
    'id': 6,
    'layerName': null,
    'nameKz': 'Электроснабжение',
    'nameRu': 'Электроснабжение',
    'geomType': null,
    'parentId': 0,
    'communal': {
      'id': 1,
      'nameRu': 'Электроснабжение',
      'nameKk': null,
      'nameEn': null
    }
  }, {
    'id': 2,
    'layerName': 'electro_opora_vl',
    'nameKz': 'Опоры воздушных линии',
    'nameRu': 'Опоры воздушных линии',
    'geomType': 'MULTIPOINT',
    'parentId': 6,
    'communal': {
      'id': 1,
      'nameRu': 'Электроснабжение',
      'nameKk': null,
      'nameEn': null
    }
  }, {
    'id': 1,
    'layerName': 'heat_truboprovod_teploseti_a',
    'nameKz': 'Сети',
    'nameRu': 'Сети',
    'geomType': 'MULTILINESTRING',
    'parentId': 5,
    'communal': {
      'id': 4,
      'nameRu': 'Теплоснабжение',
      'nameKk': null,
      'nameEn': null
    }
  // }, {
  //   'id': 4,
  //   'layerName': 'electro_opora_lep_line',
  //   'nameKz': 'Опоры ЛЭП',
  //   'nameRu': 'Опоры ЛЭП (линии)',
  //   'geomType': 'MULTILINESTRING',
  //   'parentId': 6,
  //   'communal': {
  //     'id': 1,
  //     'nameRu': 'Электроснабжение',
  //     'nameKk': null,
  //     'nameEn': null
  //   }
  }, {
    'id': 3,
    'layerName': 'electro_electrokabeli',
    'nameKz': 'Кабельные линии',
    'nameRu': 'Кабельные линии',
    'geomType': 'MULTILINESTRING',
    'parentId': 6,
    'communal': {
      'id': 1,
      'nameRu': 'Электроснабжение',
      'nameKk': null,
      'nameEn': null
    }
  }];

  constructor() {}

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    // const data = this.buildFileTree(TREE_DATA, 0);
    const table2tree = this.processTable(this.TREE_DATA, 'id', 'parentId', 0);
    // console.log('table2tree: ', table2tree);
    const data = this.buildFileTree(table2tree, 0);
    // console.log('data -> data -> data:', data);
    // Notify the change.
    this.dataChange.next(data);
  }

  getCommunalLayers(communalId: number) {
    return this.TREE_DATA.filter(lyr => lyr.communal.id === communalId && lyr.parentId !== 0);
    // return this.TREE_DATA.filter(lyr => lyr.communal.id === communalId);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): LayerItemNode[] {
    return Object.keys(obj).reduce<LayerItemNode[]>((accumulator, key) => {

      // console.log('accumulator:', accumulator);
      // console.log('key:', key);
      // console.log('obj[key]:', obj[key]);
      // console.log('-----------------------------------------------------------------------------------------------------------------');

      const value = obj[key];
      const node = new LayerItemNode();
      node.item = value.nameRu;
      node.id = value.id;
      node.layerNameRu = value.nameRu;
      node.communalId = value.communal ? value.communal.id : null;

      if (value.children && value.children.length > 0) {
        node.children = this.buildFileTree(value.children, level + 1);
      } else {
        // console.log('inner value:', value);
        node.layerName = value.layerName;
        node.layerGeom = value.geomType;
      }

      return accumulator.concat(node);
    }, []);
  }
  // buildFileTree(obj: {[key: string]: any}, level: number): LayerItemNode[] {
  //   return Object.keys(obj).reduce<LayerItemNode[]>((accumulator, key) => {
  //     const value = obj[key];
  //     const node = new LayerItemNode();
  //     node.item = key;
  //
  //     if (value != null) {
  //       if (typeof value === 'object') {
  //         node.children = this.buildFileTree(value, level + 1);
  //       } else {
  //         node.item = value;
  //       }
  //     }
  //
  //     return accumulator.concat(node);
  //   }, []);
  // }

  processTable(data, idField, foreignKey, rootLevel) {
    const hash = {};
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const id = item[idField];
      const parentId = item[foreignKey];
      hash[id] = hash[id] || [];
      hash[parentId] = hash[parentId] || [];

      item.children = hash[id];
      hash[parentId].push(item);
    }
    return hash[rootLevel];
  }
}
