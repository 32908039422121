import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-remove-layer-object',
  templateUrl: './remove-layer-object.component.html',
  styleUrls: ['./remove-layer-object.component.css']
})
export class RemoveLayerObjectComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<RemoveLayerObjectComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
  }

  onYesClick() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
