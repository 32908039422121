import {Component, OnInit} from '@angular/core';
import {NavService} from '../../../services/nav.service';

@Component({
  selector: 'app-header-card',
  templateUrl: './header-card.component.html',
  styleUrls: ['./header-card.component.css']
})

export class HeaderCardComponent implements OnInit {

  constructor(
    private navSvc: NavService
  ) { }

  ngOnInit() {
  }

  doVisible() {
    this.navSvc.toggleLeftSidebar.next(true);
  }

}
