import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-personal-cabinet-box',
  templateUrl: './personal-cabinet-box.component.html',
  styleUrls: ['./personal-cabinet-box.component.css']
})
export class PersonalCabinetBoxComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PersonalCabinetBoxComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  logOut(): void {
    this.authService.logOut();
    this.dialogRef.close();
    location.reload();
  }

}
