import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { LayersService } from 'src/app/services/layers.service';
import { LayerTextbox } from 'src/app/shared/dynamic-form-types/layer-textbox';
import { LayerBase } from 'src/app/shared/models/layer-base';
import { NavService } from '../../../services/nav.service';
import { LayerNumberBox } from 'src/app/shared/dynamic-form-types/layer-numberbox';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { DatePipe } from '@angular/common';
import { layer } from 'src/app/shared/models/layer.model';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.css']
})
export class NavListComponent implements OnInit, OnChanges {
  objectAttributesLst: layer.ObjectAttributes[] = [];
  objectAttributesModel: layer.ObjectAttributes = new layer.ObjectAttributes();

  layers = [];

  layerForm: FormGroup;
  items: FormArray;

  @ViewChild(DynamicFormComponent) layerDynamicForm: DynamicFormComponent;

  constructor(
    private navSvc: NavService,
    private lyrSvc: LayersService,
    private datePipe: DatePipe
  ) {
    this.lyrSvc.objectAttributesModel.subscribe(data => {
      this.objectAttributesModel = data;
      this.layers = [];
      this.layers = this.getLayerAttributes();
      if (this.layerDynamicForm) {
        this.layerDynamicForm.layers = this.layers;
      }
    });
  }

  ngOnInit() {
    this.navSvc.toggleAttrTablePanel.subscribe((attrTableModel: any) => {
      this.objectAttributesModel = attrTableModel;
      this.lyrSvc.getLayerAttributes(attrTableModel).subscribe(data => {
        const layerAttr = [];
        layerAttr.push(data);
        this.objectAttributesLst = [];
        layerAttr['0'].forEach(i => {
          if (i.nameEn !== 'gid' && i.nameEn !== 'id'
            && i.nameEn !== 'shape_leng' && i.nameEn !== 'shape_area') {
            this.objectAttributesLst.push(i);
          }
        });
        this.lyrSvc.objectAttributesModel.next(this.objectAttributesModel);
        this.layers = this.getLayerAttributes();
      });
    });
  }

  getLayerAttributes() {
    const arr = [];
    this.objectAttributesLst.forEach(i => {
      if (i.type === 'varchar') {
        arr.push(
          new LayerTextbox({
            key: i.nameEn,
            label: i.nameRu ? i.nameRu : i.nameEn,
            value: '',
            order: i.id
          })
        );
      } else if (i.type === 'numeric' || i.type === 'int4' || i.type === 'int8') {
        arr.push(
          new LayerNumberBox({
            key: i.nameEn,
            label: i.nameRu ? i.nameRu : i.nameEn,
            value: 0,
            type: 'number',
            order: i.id
          })
        );
      } else if (i.type === 'timestamp') {
        arr.push(
          new LayerTextbox({
            key: i.nameEn,
            label: i.nameRu ? i.nameRu : i.nameEn,
            value: this.datePipe.transform(new Date(), 'dd.MM.yyyy'),
            type: 'date',
            order: i.id
          })
        );
      } else if (i.type !== 'geometry') {
        arr.push(
          new LayerTextbox({
            key: i.nameEn,
            label: i.nameRu ? i.nameRu : i.nameEn,
            value: '',
            order: i.id
          })
        );
      // } else if (i.type === 'uuid') {
      //   arr.push(
      //     new LayerTextbox({
      //       key: i.nameEn,
      //       label: i.nameRu ? i.nameRu : i.nameEn,
      //       value: null,
      //       order: i.id
      //     })
      //   );
      }
    });
    const layers: LayerBase<any>[] = arr;
    return layers.sort((a, b) => a.order - b.order);
  }

  ngOnChanges() {
  }

}
