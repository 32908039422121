import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class NavService {
  public toggleLeftSidebar = new BehaviorSubject(false);
  public toggleAttrTablePanel = new Subject();

  constructor() { }

  getSubjectApp(): Observable<any> {
    return this.toggleAttrTablePanel.asObservable();
  }

}
