import { Injectable } from '@angular/core';

import * as L from 'leaflet';
import 'leaflet-draw';
import '../../../libs/leaflet/groupedlayercontrol/leaflet.groupedlayercontrol.min.js';

import { MapService } from './map.service';
import { CONFIG } from '../config';
import { Subject } from 'rxjs';
import { mapmodel } from '../shared/models/map.model.js';
import { layer } from '../shared/models/layer.model.js';
import { LayerItemFlatNode } from '../shared/models/tree/LayerItemFlatNode.js';

@Injectable()
export class MapControlsService {

    drawSubject = new Subject();
    editDrawSubject = new Subject();

    constructor(
        private mapSvc: MapService
    ) {
        this.initLocalization();
    }

    initScale(map: L.Map) {
        L.control.scale({
            imperial: false
        }).addTo(map);
    }

    initGroupedLayersControl(map: L.Map) {
        const baseLayers = {
            'Гибрид': this.mapSvc.googleHybridLayer,
            'Схема': this.mapSvc.googleRoadLayer,
            // 'Аэрофотосъемка, 2017 г.': this.getWmsTileLayer('ras_afs_2017_7cm')
        };
        const options = {
            position: 'bottomright'
        };
        L.control.groupedLayers(baseLayers, {}, options).addTo(map);
    }

    private getWmsTileLayer(layer_: string) {
        return L.tileLayer.wms(CONFIG.GEOSERVER_GP_URL + `atyrau/wms?service=WMS`, {
            layers: layer_, format: 'image/png', transparent: true, version: '1.1.0',
            maxZoom: 21, minZoom: 7
        });
    }

    personnalInfoControl(map: L.Map) {
        const container: any = L.DomUtil.create('input', 'persInfo-extract');
        const persInfoControl = L.Control.extend({
            options: { position: 'topright' },
            onAdd: (mapp) => {
                container.type = 'button';
                container.title = 'Выйти';
                container.style.backgroundImage = 'url(../../../../assets/images/person.png)';
                container.style.height = '38px';
                container.style.width = '38px';
                container.style.cursor = 'pointer';
                container.style.backgroundColor = 'rgb(255, 255, 255)';
                container.style.borderRadius = '19px';
                container.style.borderColor = 'rgb(204, 204, 204)';
                container.style.backgroundRepeat = 'no-repeat';
                container.style.position = 'relative';
                L.DomEvent.addListener(container, 'click', L.DomEvent.stopPropagation);
                return container;
            }
        });
        map.addControl(new persInfoControl());
        return container;
    }

    initDrawControls(map: L.Map) {
        const controls = this.initDraw(map, {
            layers: this.mapSvc.editableLayers,
            edit: false,
            remove: false
        }, {
                position: 'topright',
                draw: [
                    // 'polyline', 'polygon', 'rectangle', 'circle', 'marker', 'circlemarker'
                ]
            }
        );
        return controls;
    }

    initDraw(map: L.Map, layerOpts: any, drawOpts: any, drawObj: any = {}) {
        const drawnItems = new L.FeatureGroup();
        map.addLayer(drawnItems);
        const editableLayers = layerOpts.layers;
        const CustomMarker = L.Icon.extend({
            options: {
                iconUrl: '../../assets/images/markers/marker-icon.png',
                iconRetinaUrl: '../../assets/images/markers/marker-icon-2x.png',
                shadowUrl: '../../assets/images/markers/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize: [41, 41]
            }
        });

        const drawInstruments = [
            { name: 'polyline', obj: {} },
            {
                name: 'polygon', obj: {
                    allowIntersection: false, // Restricts shapes to simple polygons
                    drawError: {
                        color: '#e1e100',
                        message: '<strong>Oh snap!<strong> you can\'t draw that!'
                    },
                    guidelineDistance: 20,
                    // shapeOptions: { color: '#bada55' },
                    metric: true,
                    zIndexOffset: 2000,
                    repeatMode: false,
                    showArea: false
                }
            },
            { name: 'rectangle', obj: {} },
            {
                name: 'circle', obj: {
                    shapeOptions: {
                        stroke: true,
                        color: 'red',
                        weight: 17,
                        opacity: 0.7,
                        fill: true,
                        fillColor: '#f03', // same as color by default
                        fillOpacity: 0.5,
                        clickable: true
                    },
                    showRadius: true,
                    metric: true, // Whether to use the metric measurement system or imperial
                    feet: true, // When not metric, use feet instead of yards for display
                    nautic: false // When not metric, not feet use nautic mile for display
                }
            },
            {
                name: 'circlemarker', obj: {
                    stroke: true,
                    color: 'red',
                    weight: 1,
                    opacity: 1,
                    fill: true,
                    fillColor: null, // same as color by default
                    fillOpacity: 0.5,
                    clickable: true,
                    radius: 5,
                    zIndexOffset: 2000 // This should be > than the highest z-index any markers
                }
            },
            { name: 'marker', obj: { icon: new CustomMarker() } }
        ];

        for (let i = 0; i < drawInstruments.length; i++) {
            const element = drawInstruments[i];


            const controlIns = drawOpts.draw.filter(e => e === element.name);
            if (controlIns !== undefined && controlIns.length > 0) {
                drawObj[element.name] = element.obj;
            } else {
                drawObj[element.name] = false;
            }
        }
        const DrawOptions: any = {
            position: drawOpts.position,
            draw: drawObj,
            edit: {
                featureGroup: editableLayers, // REQUIRED!!
                edit: layerOpts.edit,
                remove: layerOpts.remove
            }
        };

        // @ts-ignore
        const drawControl = new L.Control.Draw(DrawOptions);
        // map.addControl(drawControl);

        // @ts-ignore
        map.on(L.Draw.Event.CREATED, (e: any) => {
            const type = e.layerType,
                layer_ = e.layer;

            editableLayers.addLayer(layer_);
            const drawSubjObj = new mapmodel.DrawToolOpts();
            drawSubjObj.drawevent = 'drawend';
            drawSubjObj.layer = layer_;
            drawSubjObj.type = type;

            this.drawSubject.next(drawSubjObj);
        });

        // @ts-ignore
        map.on(L.Draw.Event.EDITED, (e: any) => {
            console.log(e);
            const arr = [];
            e.layers.eachLayer((val: any) => {
                const drawSubjObj = new mapmodel.DrawToolOpts();
                drawSubjObj.drawevent = 'edited';
                drawSubjObj.layer = val._leaflet_id;
            //     drawSubjObj.type = type;
                arr.push(drawSubjObj);
            });
            this.editDrawSubject.next(arr);
        });

        return drawControl;
    }

    private initLocalization() {
        const drawToolbar = {
            actions: {
                title: 'Отменить рисование',
                text: 'Отменить'
            },
            finish: {
                title: 'Закончить рисование',
                text: 'Закончить'
            },
            undo: {
                title: 'Удалить последнюю нарисованную точку',
                text: 'Удалить последнюю точку'
            },
            buttons: {
                polyline: 'Нарисовать полилинию',
                polygon: 'Нарисовать полигон',
                rectangle: 'Нарисовать прямоугольник',
                circle: 'Нарисовать окружность',
                marker: 'Нарисовать маркер',
                circlemarker: 'Нарисовать круг маркер'
            }
        };

        const drawHandlers = {
            circle: {
                tooltip: {
                    start: 'Нажмите и перетащите, чтобы нарисовать круг.'
                },
                radius: 'Радиус'
            },
            circlemarker: {
                tooltip: {
                    start: 'Нажмите на карту, чтобы разместить круг маркер.'
                }
            },
            marker: {
                tooltip: {
                    start: 'Нажмите на карту, чтобы разместить маркер.'
                }
            },
            polygon: {
                error: '<strong>Ошибка:</strong>!',
                tooltip: {
                    start: 'Нажмите, чтобы начать рисовать фигуру.',
                    cont: 'Нажмите, чтобы продолжить рисование фигуры.',
                    end: 'Нажмите первую точку, чтобы замкнуть эту фигуру.'
                }
            },
            polyline: {
                error: '<strong>Ошибка:</strong> края формы не могут пересекаться!',
                tooltip: {
                    start: 'Нажмите, чтобы начать рисовать линию.',
                    cont: 'Нажмите, чтобы продолжить рисование линию.',
                    end: 'Нажмите последнюю точку для завершения линии.'
                }
            },
            rectangle: {
                tooltip: {
                    start: 'Нажмите и перетащите, чтобы нарисовать прямоугольник.'
                }
            },
            simpleshape: {
                tooltip: {
                    end: 'Отпустите мышь, чтобы закончить рисование.'
                }
            }
        };

        const editToolbar = {
            actions: {
                save: {
                    title: 'Сохранить изменения.',
                    text: 'Сохранить'
                },
                cancel: {
                    title: 'Отмена редактирования, сбрасывает все изменения.',
                    text: 'Отмена'
                },
                clearAll: {
                    title: 'Очистить все слои.',
                    text: 'Очистить все'
                }
            },
            buttons: {
                edit: 'Изменить слои.',
                editDisabled: 'Нет слоев для редактирования.',
                remove: 'Удалить слои.',
                removeDisabled: 'Нет слоев для удаления.'
            }
        };

        const editHandlers = {
            edit: {
                tooltip: {
                    text: 'Перетащите ручки или маркер для редактирования.',
                    subtext: 'Нажмите «Отмена», чтобы отменить изменения.'
                }
            },
            remove: {
                tooltip: {
                    text: 'Нажмите на объект, чтобы удалить'
                }
            }
        };

        (L as any).drawLocal.draw.toolbar = drawToolbar;
        (L as any).drawLocal.draw.handlers = drawHandlers;
        (L as any).drawLocal.edit.toolbar = editToolbar;
        (L as any).drawLocal.edit.handlers = editHandlers;
    }

    showDrawControlsByLayer(layer_: LayerItemFlatNode) {
        const drawOpts = {
            position: 'topright',
            draw: {},
            edit: {
                featureGroup: this.mapSvc.editableLayers,
                edit: true
            }
        };
        let drawControlObj = {};
        if (layer_.layerGeom === 'MULTIPOLYGON') {
            drawControlObj = {
                polyline: false,
                // polygon: false,
                // rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
            };
        } else if (layer_.layerGeom === 'MULTILINESTRING') {
            drawControlObj = {
                // polyline: false,
                polygon: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
            };
        } else if (layer_.layerGeom === 'MULTIPOINT') {
            drawControlObj = {
                polyline: false,
                polygon: false,
                rectangle: false,
                circle: false,
                marker: false,
                // circlemarker: false
            };
        } else if (layer_.layerGeom === 'POINT') {
            drawControlObj = {
                polyline: false,
                polygon: false,
                rectangle: false,
                // circle: false,
                marker: false,
                // circlemarker: false
            };
        }
        drawOpts.draw = drawControlObj;
        // @ts-ignore
        const drawControl = new L.Control.Draw(drawOpts);
        return drawControl;
    }

}
