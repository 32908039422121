import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AttrTableComponent } from './components/attr-table/attr-table.component';
import { HeaderCardComponent } from './components/common/header-card/header-card.component';
import { NavListComponent } from './components/common/nav-list/nav-list.component';
import { PersonalCabinetBoxComponent } from './components/common/personal-cabinet-box/personal-cabinet-box.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicLayerFormComponent } from './components/dynamic-layer-form/dynamic-layer-form.component';
import { LayerAttrFormWindowComponent } from './components/layer-attr-form-window/layer-attr-form-window.component';
import { LayersPanelComponent } from './components/layers-panel/layers-panel.component';
import { ApiService } from './services/api';
import { AuthService } from './services/auth.service';
import { GeomService } from './services/geom.service';
import { IdentifyService } from './services/identify.service';
import { LayerControlService } from './services/layer-control-service';
import { LayersService } from './services/layers.service';
import { MapControlsService } from './services/map.controls.service';
import { MapService } from './services/map.service';
import { NavService } from './services/nav.service';
import { MaterialModule } from './shared/material.module';
import { PrimeModule } from './shared/prime.module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { RemoveLayerObjectComponent } from './components/remove-layer-object/remove-layer-object.component';
import { BackupPanelComponent } from './components/layers-panel/backup-panel/backup-panel.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderCardComponent,
    NavListComponent,
    LayersPanelComponent,
    AttrTableComponent,
    PersonalCabinetBoxComponent,
    DynamicLayerFormComponent,
    DynamicFormComponent,
    LayerAttrFormWindowComponent,
    RemoveLayerObjectComponent,
    BackupPanelComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PrimeModule
  ],
  providers: [
    ApiService,
    MapService,
    MapControlsService,
    LayersService,
    IdentifyService,
    NavService,
    LayerControlService,
    GeomService,
    AuthService,
    DatePipe,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true
      }
    },
  ],
  entryComponents: [
    BackupPanelComponent,
    PersonalCabinetBoxComponent,
    LayerAttrFormWindowComponent,
    RemoveLayerObjectComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
