import { Component, OnInit, Input, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LayerBase } from 'src/app/shared/models/layer-base';
import { LayerControlService } from 'src/app/services/layer-control-service';
import { LayersService } from 'src/app/services/layers.service';
import { DynamicLayerFormComponent } from '../dynamic-layer-form/dynamic-layer-form.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { NavService } from 'src/app/services/nav.service';
import { layer } from 'src/app/shared/models/layer.model';
import { ApiService } from 'src/app/services/api';
import { MapService } from 'src/app/services/map.service';
import { LayerItemFlatNode } from 'src/app/shared/models/tree/LayerItemFlatNode';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() layers: LayerBase<any>[] = [];
  @Input() objectAttributesModel: layer.ObjectAttributes = new layer.ObjectAttributes();
  form: FormGroup;

  @ViewChild(DynamicLayerFormComponent) dynamicLayerForm: DynamicLayerFormComponent;

  layerObject: any;
  layerName = null;

  constructor(
    private qcs: LayerControlService,
    public lyrSvc: LayersService,
    private mapSvc: MapService,
    private navSvc: NavService,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.lyrSvc.editedLayerObject$.subscribe((obj: any) => {
      this.form = this.qcs.toFormGroup(this.layers);
      if (obj) {
        this.layerObject = obj;
        this.layers.forEach(i => {
          const keyValue = i.key;
          // @ts-ignore;
          this.form.controls[keyValue].value = obj[keyValue];
          this.form.value[keyValue] = obj[keyValue];
        });
      }
    });
    this.layerName = this.lyrSvc.selectedLayer.layerNameRu;
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.layers);
  }

  ngOnChanges() {
    this.form = this.qcs.toFormGroup(this.layers);
  }

  ngOnDestroy() {
  }

  onSubmit() {
    if (this.lyrSvc.drawedLayerObject) {
      const formValue = this.parseFormValuesByObjectAttrTypes();
      formValue['geom'] = this.lyrSvc.drawedLayerObject;
      if (this.layerObject) {
        if (this.layerObject.gid) {
          formValue['gid'] = this.layerObject.gid;
        } else {
          formValue['id'] = this.layerObject.id;
        }
        formValue['kp_uuid'] = this.layerObject.kp_uuid;
        this.updateLayerObject(formValue);
      } else {
        this.createLayerObject(formValue);
      }
      setTimeout(() => {
        this.lyrSvc.editedLayerObject.next(null);
        this.lyrSvc.isEditable = false;
        this.mapSvc.clearEditableLayers();
        this.showAttrTable(this.lyrSvc.selectedLayer);
        this.navSvc.toggleLeftSidebar.next(false);
        this.dialog.closeAll();
      }, 3000);
    } else {
      this.snackBar.open('Нарисуйте объект на карте!', '', {
        duration: 7000,
        verticalPosition: 'top'
      });
      this.dialog.closeAll();
    }
  }

  private createLayerObject(formValue) {
    this.lyrSvc.createLayerObject(this.objectAttributesModel.id, formValue)
    .pipe().subscribe(data => {
      this.form.reset();
      this.snackBar.open('Добавлен новый объект!', '', {
        duration: 7000,
        verticalPosition: 'top'
      });
    }, error => {
      this.api.showErrorMessage();
      console.log(error);
    });
  }

  private updateLayerObject(formValue) {
    this.lyrSvc.updateLayerObject(this.objectAttributesModel.id, formValue)
    .pipe().subscribe((data: any) => {
      this.form.reset();
      this.snackBar.open('Изменения успешно сохранены!', '', {
        duration: 7000,
        verticalPosition: 'top'
      });
    }, error => {
      this.api.showErrorMessage();
      console.log(error);
    });
  }

  private parseFormValuesByObjectAttrTypes() {
    const obj = {};
    this.layers.forEach((i: any) => {
      const keyValue = i.key;
      if (i.type === 'date') {
        const el = this.form.controls[keyValue].value;
        if (el && el !== undefined && el !== '') {
          const date = new Date(el.replace( /(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3'));
          obj[keyValue] = date.getTime();
        } else {
          obj[keyValue] = null;
        }
      } else if (i.type === 'number') {
        const el = this.form.controls[keyValue].value;
        if (el && el !== undefined && el !== '') {
          obj[keyValue] = JSON.parse(el);
        } else {
          obj[keyValue] = null;
        }
      } else {
        obj[keyValue] = this.form.value[keyValue];
      }
    });
    return obj;
  }

  private showAttrTable(node: LayerItemFlatNode) {
    this.lyrSvc.toggleLayerByName(node.layerName);
    this.navSvc.toggleAttrTablePanel.next(node);
    this.lyrSvc.toggleLayerByName(node.layerName);
  }

  resetForm() {
    this.form.reset();
  }

}
