import { Injectable } from '@angular/core';

import * as L from 'leaflet';
import { LayersService } from './layers.service';

@Injectable()
export class GeomService {
    public map: L.Map;

    WGS84 = '+proj=longlat +datum=WGS84 +no_defs';
    EPSG97 = `+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=-58000 +y_0=-5205500 +ellps=krass
            +units=m +towgs84=-60.873,-78.533,-110.658,2.63496,2.70103,-0.58073,6.158113 +no_defs`;

    constructor(
        private lyrSvc: LayersService
    ) { }

    getObject(geomStr) {
        const geoJsonData = JSON.parse(geomStr);
        let coords = null;
        let object = null;
        const geoDataType = geoJsonData.geometry.type;

        if (geoJsonData.geometry && geoJsonData.geometry.coordinates) {
            if (geoDataType === 'Polygon') {
                coords = geoJsonData.geometry.coordinates[0];
                coords.forEach(coord => {
                    const LatLng = L.GeoJSON.coordsToLatLng(coord);
                    coord[0] = LatLng.lat;
                    coord[1] = LatLng.lng;
                });
            } else if (geoDataType === 'LineString') {
                coords = geoJsonData.geometry.coordinates;
                coords.forEach(coord => {
                    const LatLng = L.GeoJSON.coordsToLatLng(coord);
                    coord[0] = LatLng.lat;
                    coord[1] = LatLng.lng;
                });
                object = L.polyline([coords]);
            } else if (geoDataType === 'MultiLineString') {
                coords = geoJsonData.geometry.coordinates[0];
                coords.forEach(coord => {
                    const LatLng = L.GeoJSON.coordsToLatLng(coord);
                    coord[0] = LatLng.lat;
                    coord[1] = LatLng.lng;
                });
                object = L.polyline([coords]);
            } else if (geoDataType === 'Point') {
                coords = geoJsonData.geometry.coordinates;
                const LatLng = L.GeoJSON.coordsToLatLng(coords);
                coords[0] = LatLng.lat;
                coords[1] = LatLng.lng;
            }
        } else {
            return null;
        }

        if (geoDataType === 'Polygon') {
            object = L.polygon([coords]);
        } else if (geoDataType === 'Point') {
            object = L.circle(coords, {
                color: 'red',
                fillColor: '#f03',
                fillOpacity: 0.5,
                radius: 500
            });
        } else if (geoDataType === 'Marker') {
            object = L.marker([coords[0], coords[1]]);
        }
        if (object) {
            return object;
        } else {
            return null;
        }
    }

    toWKT(layer: any, project: boolean) {
        let lng, lat, point;
        const coords = [];
        if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
            const latlngs: any = layer.getLatLngs();
            // for (let i = 0; i < latlngs.length; i++) {
            //     const latlngsProjected: any = L.Projection.SphericalMercator.project(latlngs[i]);
            //     coords.push(latlngsProjected.x + ' ' + latlngsProjected.y);
            //     if (i === 0) {
            //         lng = latlngs[i].lng;
            //         lat = latlngs[i].lat;
            //     }
            // }
          for (let i = 0; i < latlngs.length; i++) {
            const latlngs1: any = latlngs[i];
            if (latlngs1.length) {
              for (let j = 0; j < latlngs1.length; j++) {
                if (project) {
                  const latlngsProjected: any = L.Projection.SphericalMercator.project(latlngs1[j]);
                  coords.push(latlngsProjected.x + ' ' + latlngsProjected.y);
                } else {
                  coords.push(latlngs1[j].lng + ' ' + latlngs1[j].lat);
                }
                if (j === 0) {
                  if (project) {
                    const latlngsProjec: any = L.Projection.SphericalMercator.project(latlngs1[j]);
                    lng = latlngsProjec.x;
                    lat = latlngsProjec.y;
                  } else {
                    lng = latlngs1[j].lng;
                    lat = latlngs1[j].lat;
                  }
                }
              }
            } else {
              if (project) {
                const latlngsProjected: any = L.Projection.SphericalMercator.project(latlngs1); // latlngs1[i]
                coords.push(latlngsProjected.x + ' ' + latlngsProjected.y);
              } else {
                coords.push(latlngs[i].lng + ' ' + latlngs[i].lat);
              }
              if (i === 0) {
                if (project) {
                  const latlngsProjec: any = L.Projection.SphericalMercator.project(latlngs[i]);
                  lng = latlngsProjec.x;
                  lat = latlngsProjec.y;
                } else {
                  lng = latlngs[i].lng;
                  lat = latlngs[i].lat;
                }
              }
            }
          }
          if (layer instanceof L.Polygon) {
            return 'POLYGON((' + coords.join(',') + ',' + lng + ' ' + lat + '))';
          } else if (layer instanceof L.Polyline) {
            return 'LINESTRING(' + coords.join(',') + ')';
          }
        } else if (layer instanceof L.Marker) {
            if (project) {
                const latlngsProjec: any = L.Projection.SphericalMercator.project(layer.getLatLng());
                point = 'POINT(' + latlngsProjec.x + ' ' + latlngsProjec.y + ')';
            } else {
                point = 'POINT(' + layer.getLatLng().lng + ' ' + layer.getLatLng().lat + ')';
            }
            return point;
        } else if (layer instanceof L.Circle || layer instanceof L.CircleMarker) {
            if (project) {
                const latlngsProjec: any = L.Projection.SphericalMercator.project(layer.getLatLng());
                point = 'POINT(' + latlngsProjec.x + ' ' + latlngsProjec.y + ')';
            } else {
                point = 'POINT(' + layer.getLatLng().lng + ' ' + layer.getLatLng().lat + ')';
            }
            return point;
        }
    }

}
