export class LayerItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;

  id: number;
  communalId: number;

  layerName: string;
  layerNameRu: string;
  layerGeom: string;
  layerType: string;
  isEditableTable: boolean;
  isOriginShown: boolean;
}
