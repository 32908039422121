import {NgModule} from '@angular/core';
import {CdkTableModule} from '@angular/cdk/table';

import {
  MatInputModule,
  MatButtonModule,
  MatSidenavModule,
  MatMenuModule,
  MatIconModule,
  MatTreeModule,
  MatCheckboxModule,
  MatListModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatCardModule,
  MatSortModule,
  MatTooltipModule
} from '@angular/material';

@NgModule({
  imports: [],
  exports: [
    CdkTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSidenavModule,
    MatSortModule,
    MatTreeModule,
    MatTooltipModule,
  ],
})
export class MaterialModule {
}
