import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as L from 'leaflet';
import { CONFIG } from '../config';
import { LayersService } from './layers.service';
import { MapService } from './map.service';

@Injectable()
export class IdentifyService {
  layerName: string;
  constructor(
    private http: HttpClient,
    private lyrSvc: LayersService,
    private mapSvc: MapService
  ) {
  }

  showGetFeatureInfo(latlng) {
    // const popup = L.popup()
    //   .setLatLng(latlng)
    //   // .setContent('<br />')
    //   .setContent('<i class="pi pi-spin pi-spinner" style="font-size:3em;margin-left:8px;color:#808080;"></i>')
    //   .openOn(map);
    // const LatLng = L.Projection.SphericalMercator.project(latlng);
    const map = this.mapSvc.getMap();
    const resp = this.getFeatureInfo(map, latlng);
    resp.subscribe(
      (data: any) => {
        // console.log('layers:', data);
        if (data.features && data.features.length > 0) {
          this.layerName = data.features['0'].id.split('.');
          // this.buildFeatureInfoContent(data, popup);
          this.http.post('/eqyzmet/api/engineering/get/layers', {
            layerName: this.layerName[0]
          }).subscribe(res => {
            if (res) {
              this.showPopup(data, latlng, res);
            } else {
              this.showPopup(data, latlng, null);
            }
          }, err => {
            this.showPopup(data, latlng, null);
          });
        } else {
          // popup.setContent('<p>Ничего не найдено</p>');
        }
        // console.log('data: ', data);
      },
      error => {
        console.log('error: ', error);
        // popup.setContent('<p>Ничего не найдено</p>');
      }
    );
    // console.log('resp: ', resp);
  }

  showGetFeatureInfoOld(map: L.Map, latlng) {
    // const popup = L.popup()
    //   .setLatLng(latlng)
    //   // .setContent('<br />')
    //   .setContent('<i class="pi pi-spin pi-spinner" style="font-size:3em;margin-left:8px;color:#808080;"></i>')
    //   .openOn(map);
    // const LatLng = L.Projection.SphericalMercator.project(latlng);
    const resp = this.getFeatureInfo(map, latlng);
    resp.subscribe(
      (data: any) => {
        if (data.features && data.features.length > 0) {
          this.layerName = data.features['0'].id.split('.');
          this.http.post('/eqyzmet/api/engineering/get/layers', {
            layerName: this.layerName[0]
          }).subscribe(res => {
            if (res) {
              const attrName = res;
              this.showPopup(data, latlng, res);
            }
          }, err => {
            console.log('not attrs');
            this.showPopup(data, latlng, null);
          });
        } else {
          // popup.setContent('<p>Ничего не найдено</p>');
        }
        console.log('data: ', data);
      },
      error => {
        console.log('error: ', error);
        // popup.setContent('<p>Ничего не найдено</p>');
      }
    );
    // console.log('resp: ', resp);
  }

  private getFeatureInfo(map: L.Map, latlng) {
    const baseUrl = CONFIG.GEOSERVER_URL + 'gis/wms?';
    const url = this.getFeatureInfoUrl(baseUrl, map, latlng);
    return this.http.get(url);
  }

  private getFeatureInfoUrl(baseurl: string, map: L.Map, latlng: L.LatLng) {
    const point = map.latLngToContainerPoint(latlng),
      size = map.getSize(),
      params = {
        request: 'GetFeatureInfo',
        service: 'WMS',
        srs: 'EPSG:3857',
        // styles: '',
        transparent: true,
        version: '1.1.1',
        format: 'image/png',
        // bbox: map.getBounds().toBBoxString(),
        bbox: this.getMercatorBBoxString(map),
        height: size.y,
        width: size.x,
        layers: this.lyrSvc.identifyLayersLst, // ['utilities:heat_teplovie_punkti_a'],
        query_layers: this.lyrSvc.identifyLayersLst, // ['utilities:heat_teplovie_punkti_a'],
        info_format: 'application/json',
        // info_format: 'text/html'
      };

    params[params.version === '1.3.0' ? 'i' : 'x'] = Math.round(point.x);
    params[params.version === '1.3.0' ? 'j' : 'y'] = Math.round(point.y);

    return baseurl + L.Util.getParamString(params, baseurl, true);
  }

  getMercatorBBoxString(map: L.Map) {
    const bounds = map.getBounds();
    const west = L.Projection.SphericalMercator.project(bounds.getNorthWest()).x;
    const south = L.Projection.SphericalMercator.project(bounds.getSouthWest()).y;
    const east = L.Projection.SphericalMercator.project(bounds.getNorthEast()).x;
    const north = L.Projection.SphericalMercator.project(bounds.getNorthWest()).y;
    return west + ',' + south + ',' + east + ',' + north + '';
  }

  buildFeatureInfoContent(data: any, popup: any) {
    const props = data.features['0'].properties;
    let content = '';
    // let content = '<p>' + props.layername + '<br />';
    for (const item in props) {
      // console.log(item + ': ' + props[item]);
      const propValue = props[item];
      if (propValue && item !== 'shape_area' && item !== 'shape_leng') {
        content += '<p><strong>' + item + '</strong>: ' + propValue + '<br />';
      }
      popup.setContent(content);
    }
  }

  showPopup(data: any, latlng, attrs?: any) {
    const popup = L.popup()
      .setLatLng(latlng)
      // .setContent('<br />')
      .setContent('<i class="pi pi-spin pi-spinner" style="font-size:3em;margin-left:8px;color:#808080;"></i>')
      .openOn(this.mapSvc.getMap());
    const props = data.features['0'].properties;
    let content = '';
    // let content = '<p>' + props.layername + '<br />';
    for (const item in props) {
      let attr;
      if (attrs !== undefined && attrs) {
        attr = attrs.filter(a => a.nameEn === item);
      }
      if (attrs !== undefined && attrs && attr.length > 0) {
        attr = attrs ? attr[0].nameRu : item;
      } else {
        attr = item;
      }
      // console.log(item + ': ' + props[item]);
      const propValue = props[item];
      if (propValue && item !== 'shape_area' && item !== 'shape_leng') {
        content += '<p><strong>' + attr + '</strong>: ' + propValue + '<br />';
      }
    }
    if (content && content !== '') {
      popup.setContent(content);
    } else {
      popup.setContent('<p>Нет данных</p>');
    }
  }
}
