import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import {BehaviorSubject, Observable} from 'rxjs';
import { CONFIG } from '../config';
import { MapService } from './map.service';
import { LayerItemFlatNode } from '../shared/models/tree/LayerItemFlatNode';

const TREE_DATA = {
  'Теплоснабжение и теплоотведение': [
    '1|local_heat_truboprovod_teploseti_a|Сети|MULTILINESTRING|LOC|4',
    '1|heat_truboprovod_teploseti_a|Сети(эталонный)|MULTILINESTRING|ORI|4',
    '1|history_heat_truboprovod_teploseti_a|Сети(архивный)|MULTILINESTRING|HIS|4'
  ],
  'Электроснабжение': [
    '2|local_electro_opora_vl|Опоры воздушных линии|MULTIPOINT|ORI|1',
    '2|electro_opora_vl|Опоры воздушных линии(эталонный)|MULTIPOINT|ORI|1',
    '2|history_electro_opora_vl|Опоры воздушных линии(архивный)|MULTIPOINT|ORI|1',

    '4|local_electro_opora_lep_line|Опоры ЛЭП(линии)|MULTILINESTRING|ORI|1',
    '4|electro_opora_lep_line|Опоры ЛЭП(линии)(эталонный)|MULTILINESTRING|ORI|1',
    '4|history_electro_opora_lep_line|Опоры ЛЭП(линии)(архивный)|MULTILINESTRING|ORI|1',

    '3|local_electro_electrokabeli|Кабельные линии|MULTILINESTRING|ORI|1',
    '3|electro_electrokabeli|Кабельные линии(эталонный)|MULTILINESTRING|ORI|1',
    '3|history_electro_electrokabeli|Кабельные линии(архивный)|MULTILINESTRING|ORI|1'
  ]
};

@Injectable()
export class LayersService {
  identifyLayersLst: string[] = [];

  objectAttributesModel = new BehaviorSubject<any>(null);
  objectAttributesModel$ = this.objectAttributesModel.asObservable();

  editedLayerObject = new BehaviorSubject<any>(null);
  editedLayerObject$ = this.editedLayerObject.asObservable();

  selectedLayer: LayerItemFlatNode = null;
  drawedLayerObject = null;
  isEditable = false;

  constructor(
    private http: HttpClient,
    private mapSvc: MapService
  ) {
    this.initialize();
  }


  initialize() {}

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TreeNode`.
   */
  // buildFileTree(value: any, level: number) {
  //   const data: any[] = [];
  //   // tslint:disable-next-line:forin
  //   for (const k in value) {
  //     const v = value[k];
  //     const node = new TreeNode();
  //     node.item = `${k}`;
  //     node.layerNameRu = `${k}`;
  //     if (v === null || v === undefined) {
  //       // no action
  //     } else if (typeof v === 'object') {
  //       node.children = this.buildFileTree(v, level + 1);
  //     } else {
  //       const layerNames = v.split('|');
  //       node.id = layerNames[0];
  //       node.item = layerNames[1];
  //       node.layerNameRu = layerNames[2];
  //       node.layerGeom = layerNames[3];
  //       node.layerType = layerNames[4];
  //       node.communalId = layerNames[layerNames.length - 1];
  //     }
  //     data.push(node);
  //   }
  //   return data;
  // }

  toggleLayerByName(lyrName: string) {
    // console.log('node: ', node);
    const map = this.mapSvc.getMap();
    let searchIndex = 0, searchLyr = null;
    // console.log('lyrName: ', lyrName);
    map.eachLayer((lyr: any) => {
      const layerName = lyr.options.layers;
      if (layerName !== undefined && layerName === lyrName) {
        ++searchIndex;
        searchLyr = lyr;

        if (this.identifyLayersLst.length > 0) {
          this.identifyLayersLst = this.identifyLayersLst.filter(l => l !== lyrName);
        }
      }
    });
    if (searchIndex > 0 && searchLyr) {
      map.removeLayer(searchLyr);
    } else {
      this.identifyLayersLst.push(lyrName);
      // this.addLayerByName(lyrName, node.isEditableTable);
      this.addLayerByName(map, lyrName);
    }
    this.mapSvc.setMap(map);
  }

  private addLayerByName(map: L.Map, lyrName: string) {
    const tileLyr = L.tileLayer.wms(CONFIG.GEOSERVER_URL + `gis/wms?service=WMS`, {
      layers: lyrName,
      format: 'image/png',
      transparent: true,
      version: '1.1.0',
      zIndex: 10,
      maxZoom: 21,
      minZoom: 7
    }).addTo(map);
    this.mapSvc.setMap(map);
  }

  public getFeatureData(layerName: string, uuid: number) {
    return this.getFeatureDataReq(layerName, uuid);
  }

  private getFeatureDataReq(lyrName: string, uuid: number) {
    const baseUrl = CONFIG.GEOSERVER_URL + 'wfs?';
    const url = this.getFeatureDataUri(baseUrl, lyrName, uuid);
    return this.http.get(url);
  }

  private getFeatureDataUri(baseurl: string, lyrName: string, uuid: number) {
    const params = {
      service: 'WFS',
      version: '1.1.0',
      request: 'GetFeature',
      dataType: 'jsonp',
      typeName: lyrName,
      // maxFeatures: 1,
      outputFormat: 'application/json',
      'CQL_FILTER': `kp_uuid='${uuid}'`
    };
    return baseurl + L.Util.getParamString(params, baseurl, true);
  }

  public getLayerAttributes(layer_: LayerItemFlatNode): Observable<any> {
    return this.http.get('/eqyzmet/api/layers/' + layer_.id + '/attrs');
  }

  public createLayerObject(layerObjectId: number, formValue) {
    const token = localStorage.getItem('access_token');
    return this.http.post(`eqyzmet/api/layers/${layerObjectId}/objects`, formValue, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    });
  }

  public updateLayerObject(layerObjectId: number, formValue) {
    const token = localStorage.getItem('access_token');
    return this.http.put(`eqyzmet/api/layers/${layerObjectId}/objects`, formValue, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    });
  }

  public removeLayerObject(layerId: number, layerObjectId: number) {
    const token = localStorage.getItem('access_token');
    return this.http.delete(`eqyzmet/api/layers/${layerId}/objects/${layerObjectId}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    });
  }

  public layerSync(layerId: number) {
    return this.http.post<any>('eqyzmet/api/layers/' + layerId + '/sync', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response'
    });
  }

}

