export namespace layer {
    export class Layer {
        constructor(
            public id: number = null,
            public communalId: number = null,
            public featureCount: number = null,
            public item: string = '',
            public layerGeom: string = '',
            public layerType: string = '',
            public layerNameRu: string = '',
            public level: number = null,
            public visibility: boolean = false,
            public isEditableTable: boolean = false,
            public isOriginShown: boolean = false,
            public expandable: boolean = false
        ) {}
    }

    export class ObjectAttributes {
        constructor(
            public id: number = null,
            public layerId: number = null,
            // public enum: any = null,
            public main: boolean = false,
            public nameEn: string = '',
            public nameKz: string = '',
            public nameRu: string = '',
            public type: string = ''
        ) {}
    }
}
