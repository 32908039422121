import {Injectable, ɵGetterFn} from '@angular/core';

import * as L from 'leaflet';
import {LatLng, point} from 'leaflet';
import { FeatureCollection } from 'geojson';

@Injectable()
export class MapService {
  private map: L.Map;
  private drawControls;
  public editableLayers: L.FeatureGroup = new L.FeatureGroup();

  public CustomMarker = {
    fillColor: '#ff7800',
    color: '#000',
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8
  };
  public googleHybridLayer = L.tileLayer(
    'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
      maxZoom: 21,
      minZoom: 7,
      attribution: ``
    });
  public googleRoadLayer = L.tileLayer(
    'http://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}&s=Galileo', {
      maxZoom: 21,
      minZoom: 7,
      attribution: ``
    });

  constructor() {
  }

  getMap() {
    return this.map;
  }

  setMap(mapIn: L.Map) {
    this.map = mapIn;
  }

  getDrawControls() {
    return this.drawControls;
  }

  setDrawControls(drawControls: any) {
    this.drawControls = drawControls;
  }

  initMap(target: string): L.Map {
    const map = L.map(target, {zoomControl: false}).setView([52.284962, 76.970215], 12);

    this.googleRoadLayer.addTo(map);

    const zoomControl = L.control.zoom({position: 'topright'});
    map.addControl(zoomControl);

    this.map = map;
    return map;
  }

  showFeatureGeom(geoJson: any) {
    if (geoJson.features[0].geometry.type === 'Point') {
      this.showThePoint(geoJson, this.CustomMarker);
    } else if (geoJson.features[0].geometry.type === 'MultiPolygon') {
      this.showTheMultiPolygon(geoJson, this.CustomMarker);
    } else if (geoJson.features[0].geometry.type === 'MultiLineString'
            || geoJson.features[0].geometry.type === 'LineString') {
      this.showTheMultiLine(geoJson, this.CustomMarker);
    } else if (geoJson.features[0].geometry.type === 'MultiPoint') {
      this.showTheMultiPoint(geoJson, this.CustomMarker);
    }
  }

  showThePoint(geoJson, marker) {
    const pointLatLng = L.Projection.SphericalMercator.unproject(
      L.point(geoJson.features[0].geometry.coordinates[0], geoJson.features[0].geometry.coordinates[1]));
    geoJson.features[0].geometry.coordinates[0] = pointLatLng.lng;
    geoJson.features[0].geometry.coordinates[1] = pointLatLng.lat;

    const geo = L.geoJSON(geoJson, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, marker);
      }
    }).addTo(this.map);
    this.map.fitBounds(geo.getBounds());
  }

  showTheMultiPolygon(geoJson, marker) {
    const coords = geoJson.features[0].geometry.coordinates[0][0];
    coords.forEach(function (item, i, arr) {
      const pointLatLng = L.Projection.SphericalMercator.unproject(L.point(item[0], item[1]));
      item[0] = pointLatLng.lng;
      item[1] = pointLatLng.lat;
    });
    geoJson.features[0].geometry.coordinates[0][0] = coords;

    const geo = L.geoJSON(geoJson, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, marker);
      }
    }).addTo(this.map);
    this.map.fitBounds(geo.getBounds());
  }

  showTheMultiLine(geoJson, marker) {
    const coords = geoJson.features[0].geometry.coordinates;
    const spCoords = [];

    for (let i = 0; i < coords.length; i++) {
      const c = coords[i];
      const point = [];
      const LatLng = L.Projection.SphericalMercator.unproject(L.point(c));
      point.push(LatLng.lat);
      point.push(LatLng.lng);
      spCoords.push(point);
    }
    // coords.forEach(function (item, i) {
    //   const pointLatLng = L.Projection.SphericalMercator.unproject(L.point(item[0], item[1]));
    //   item[0] = pointLatLng.lng;
    //   item[1] = pointLatLng.lat;
    // });
    geoJson.features[0].geometry.coordinates[0] = spCoords;
    const MultiLine = L.polyline(spCoords);
    // MultiLine.addTo(this.map);
    this.clearEditableLayers();
    this.editableLayers.addLayer(MultiLine);
    // const MultiLine = L.geoJSON(geoJson, {
    //   // pointToLayer: function (feature, latlng) {
    //   //   return L.circleMarker(latlng, marker);
    //   // }
    // }).addTo(this.map);
    this.map.fitBounds(MultiLine.getBounds());
  }

  showTheMultiPoint(geoJson, marker) {
    const coords = geoJson.features[0].geometry.coordinates;
    const pointLatLng = L.Projection.SphericalMercator.unproject(L.point(coords[0], coords[1]));
    geoJson.features[0].geometry.coordinates = pointLatLng;

    const MultiPoint: any = L.circleMarker(pointLatLng);
    this.clearEditableLayers();
    this.editableLayers.addLayer(MultiPoint);
    const geo = L.geoJSON(geoJson, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng);
      }
    });
    this.map.fitBounds(L.latLngBounds([pointLatLng]));
  }

  clearEditableLayers() {
    if (this.editableLayers.getLayers().length > 0) {
      this.editableLayers.clearLayers();
    }
  }

}
