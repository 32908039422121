import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import * as L from 'leaflet';
import { Message } from 'primeng/api';
import { PersonalCabinetBoxComponent } from './components/common/personal-cabinet-box/personal-cabinet-box.component';
import { LayerAttrFormWindowComponent } from './components/layer-attr-form-window/layer-attr-form-window.component';
import { AuthService } from './services/auth.service';
import { GeomService } from './services/geom.service';
import { IdentifyService } from './services/identify.service';
import { LayersService } from './services/layers.service';
import { MapControlsService } from './services/map.controls.service';
import { MapService } from './services/map.service';
import { NavService } from './services/nav.service';
import { auth } from './shared/models/auth.model';
import { ROLES } from './shared/utils/constants';
import { LayerItemFlatNode } from './shared/models/tree/LayerItemFlatNode';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  isVisible = false;
  display = true;
  msgs: Message[] = [];
  currentUser: auth.User = null;

  username = '';
  password = '';

  constructor(
    private mapSvc: MapService,
    private mapCtrlSvc: MapControlsService,
    private geomSvc: GeomService,
    private lyrSvc: LayersService,
    private identifySvc: IdentifyService,
    private navSvc: NavService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.initToggleLeftSidebar();
    this.navSvc.toggleAttrTablePanel.subscribe((layer_: LayerItemFlatNode) => {
      if (layer_) {
        this.mapSvc.getMap().removeControl(this.mapSvc.getDrawControls());
        const drawControls = this.mapCtrlSvc.showDrawControlsByLayer(layer_);
        this.mapSvc.setDrawControls(drawControls);
        this.mapSvc.getMap().addControl(drawControls);
      }
    });
  }

  ngOnInit() {
    this.currenUserData();
  }

  ngOnDestroy() {
    this.navSvc.toggleAttrTablePanel.unsubscribe();
  }

  ngAfterViewInit() {
    const map: L.Map = this.mapSvc.initMap('mapid');
    this.mapSvc.setMap(map);

    this.mapSvc.getMap().addLayer(this.mapSvc.editableLayers);

    this.mapSvc.getMap().on('click', (evt: any) => {
      this.identifySvc.showGetFeatureInfo(evt.latlng);
    });

    this.mapCtrlSvc.initScale(this.mapSvc.getMap());

    const controlContainer = this.mapCtrlSvc.personnalInfoControl(this.mapSvc.getMap());

    const drawControls = this.mapCtrlSvc.initDrawControls(this.mapSvc.getMap());
    this.mapSvc.setDrawControls(drawControls);
    this.mapSvc.getMap().addControl(drawControls);

    this.mapCtrlSvc.initGroupedLayersControl(this.mapSvc.getMap());

    controlContainer.onclick = () => {
      this.initUserData();
    };
    this.initDrawSubscription();
    this.initDrawEditSubscription();
  }

  private initDrawSubscription() {
    this.mapCtrlSvc.drawSubject.subscribe((val: any) => {
      if (val.drawevent === 'drawend') {
        // this.cleanAllFeatures();
        this.mapSvc.editableLayers.addLayer(val.layer);
        const geoData = val.layer.toGeoJSON();
        this.fillObjectGeom(geoData, val.layer, true);
      }
    });
  }

  initDrawEditSubscription() {
    this.mapCtrlSvc.editDrawSubject.subscribe((val: any) => {
      let editedLayer = null;
      if (val && val.length > 0) {
        val.forEach(element => {
          this.mapSvc.getMap().eachLayer((e: any) => {
            if (e._leaflet_id === element.layer) {
              editedLayer = e;
            }
          });
        });
        // this.cleanAllFeatures();
        const geoData = editedLayer.toGeoJSON();
        geoData.properties.isdrawn = true;
        const drawedObject = this.geomSvc.toWKT(editedLayer, true);
        this.lyrSvc.drawedLayerObject = drawedObject;
        if (this.lyrSvc.isEditable) {
          this.navSvc.toggleLeftSidebar.next(true);
        } else {
          this.openLayerAttributesForm();
        }
      }
    });
  }

  fillObjectGeom(geoData, layer_, isdrawn) {
    geoData.properties.isdrawn = isdrawn;
    const drawedObject = this.geomSvc.toWKT(layer_, true);
    this.lyrSvc.drawedLayerObject = drawedObject;
    if (this.lyrSvc.selectedLayer && isdrawn && !this.lyrSvc.isEditable) {
      this.openLayerAttributesForm();
    } else {
      this.navSvc.toggleLeftSidebar.next(true);
    }
  }

  openLayerAttributesForm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '80%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.lyrSvc.selectedLayer;
    const dialogRef = this.dialog.open(LayerAttrFormWindowComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  cleanAllFeatures() {
    if (this.mapSvc.editableLayers.getLayers().length > 0) {
      this.mapSvc.editableLayers.clearLayers();
      this.lyrSvc.drawedLayerObject = null;
    }
  }

  initToggleLeftSidebar() {
    this.navSvc.toggleLeftSidebar.subscribe((visibility: boolean) => {
      this.isVisible = visibility;
    });
  }

  initUserData() {
    if (this.currentUser) {
      const firstName = this.currentUser.firstName;
      const lastName = this.currentUser.lastName;
      const title = firstName + ' ' + lastName;
      const orgName = this.currentUser.organization !== null ? this.currentUser.organization.name : '';
      this.showDialogBox(title, orgName);
    }
  }

  showDialogBox(msg: string, orgName: string) {
    let dialogRef = null;
    if (!dialogRef) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '320px';
      dialogConfig.autoFocus = false;
      dialogConfig.height = '170px';
      dialogConfig.position = {
        'top': '50px',
        right: '25px'
      };
      dialogConfig.data = { title: msg, message: orgName };
      dialogRef = this.dialog.open(PersonalCabinetBoxComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef = null;
      });
    }
  }

  login() {
    this.authService.logIn(this.username, this.password).subscribe((data: any) => {
      if (data && data.access_token) {
        this.onSuccessLogin(data);
        this.username = '';
        this.password = '';
      }
    }, (error: any) => {
      console.log(error);
      if (error && error.error && error.error.message) {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Ошибка входа', detail: error.error.message });
        this.display = true;
        this.currentUser = null;
      }
    });
  }

  onSuccessLogin(data) {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('token', data.token);
    this.authService.getCurrentUser().subscribe((user: auth.User) => {
      if (user) {
        this.authService.userInfo$.next(user);
        this.authService.currentUser = user;
        this.currentUser = this.authService.currentUser;
      } else {
        this.authService.userInfo$.next(null);
      }
    });

    this.authService.getCurrentDetailFromGeoportal().subscribe((res: any) => {
      localStorage.setItem('currentuserdetail', JSON.stringify(res));
    });

    const tokenData = this.authService.getTokenData();
    if (tokenData.authorities
      // && this.tokenData.authorities.some(e => e.startsWith(ROLES.ENG_NET) || e.startsWith(ROLES.ENG_NET_EDIT))
      ) {
      this.display = false;
      this.currentUser = this.authService.currentUser;
      this.authService.userInfo$.next(this.currentUser);
    } else {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Ошибка входа', detail: 'Нет доступа для Вашей роли' });
      this.display = true;
      this.currentUser = null;
    }
  }

  currenUserData() {
    this.authService.userInfo$.subscribe((data: auth.User) => {
      if (data) {
        this.currentUser = data;
        const tokenData = this.authService.getTokenData();
        if (tokenData) {
          this.display = false;
        }
      } else {
        this.currentUser = null;
        this.display = true;
      }
    });
  }

}
